import styled from '@emotion/styled'

import { breakpoints } from '~/utils/styles'

export const TickerContainer = styled.div`
  position: fixed;
  top: ${props => (props.translate ? 30 : 50)}%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  height: 180px;
  box-sizing: border-box;
  transition: top 0.2s ease-in-out;
`
export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;

  justify-content: space-evenly;

  @media (max-width: ${breakpoints.s}px) {
  }
`

export const Product = styled.div`
  height: 180px;
  width: auto;
  cursor: pointer;
  margin-right: 60px;
`

export const Title = styled.span`
  font-weight: 300;
  font-size: 1.2rem;
  text-align: center;
`

export const PriceTag = styled.span`
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
  margin-top: 15px;

  :before {
    content: '- ';
  }
`

export const PlaceholderImg = styled.img`
  max-height: 100%;
`
