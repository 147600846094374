import React, { useContext, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import ProductGrid from '../ProductGrid'
import { Container, CategoryNav, Item } from './styles'

const ProductDetails = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [productType], order: DESC }) {
          group(field: productType) {
            fieldValue
            totalCount
          }
        }
      }
    `
  )
  const {
    store: { category },
    updateCategory,
  } = useContext(StoreContext)

  // set category to first available on page load
  useEffect(() => {
    if (!category) {
      updateCategory(allShopifyProduct.group[0].fieldValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <ProductGrid category={category} />
      <CategoryNav>
        {allShopifyProduct.group ? (
          allShopifyProduct.group.map(
            ({ fieldValue, totalCount }) =>
              totalCount && (
                <Item
                  key={fieldValue}
                  onClick={() => updateCategory(fieldValue)}
                  isActive={!!(fieldValue === category)}
                >
                  {fieldValue}
                </Item>
              )
          )
        ) : (
          <p>No product categories found.</p>
        )}
      </CategoryNav>
    </Container>
  )
}

export default ProductDetails
