import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import PageVisibility from 'react-page-visibility'
import Ticker from 'react-ticker'
import { Grid, Product, TickerContainer, PlaceholderImg } from './styles'
import { Img } from '~/utils/styles'
import placeholder from '~/images/evwhere_placeholder.svg'
import StoreContext from '~/context/StoreContext'

const ProductGrid = ({ category }) => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          group(field: productType) {
            fieldValue
            totalCount
            edges {
              node {
                id
                title
                handle
                createdAt
                images {
                  id
                  originalSrc
                  localFile {
                    childImageSharp {
                      fixed(height: 180) {
                        ...GatsbyImageSharpFixed_noBase64
                      }
                    }
                  }
                }

                variants {
                  price
                }
              }
            }
          }
        }
      }
    `
  )
  const [pageIsVisible, setPageIsVisible] = useState(true)
  const [move, setMove] = useState(true)
  const {
    store: { productOrder },
    updateProductOrder,
  } = useContext(StoreContext)

  const productCategory = allShopifyProduct.group.find(
    n => n.fieldValue === category
  )

  const selectProduct = ({ index, handle }) => {
    reorderProducts(index)
    navigate(`/product/${handle}/`)
  }
  const reorderProducts = activeIndex => {
    let currentOrder =
      productOrder.length === 0
        ? productCategory.edges.map((edge, index) => index)
        : productOrder

    let newOrder = [
      ...currentOrder.slice(activeIndex - 3), //offset might shift for mobile - depends on how many elements can fit on screen
      ...currentOrder.slice(0, activeIndex - 3),
    ]

    updateProductOrder(newOrder)
  }

  const renderProducts = edges => {
    let orderedEdges = productOrder.length
      ? productOrder.map(index => edges[index])
      : edges

    return orderedEdges.map(
      (
        {
          node: {
            id,
            handle,
            images,
            variants: [firstVariant],
          },
        },
        index
      ) => (
        <Product
          key={index + '-' + id}
          onClick={() => selectProduct({ index, handle })}
        >
          {/* <Link to={`/product/${handle}/`}> */}
          {images.length && images[0] && images[0].localFile ? (
            <Img
              loading="eager"
              // fluid={images[0].localFile.childImageSharp.fluid}
              fixed={images[0].localFile.childImageSharp.fixed}
              alt={handle}
            />
          ) : (
            <PlaceholderImg src={placeholder} />
          )}
          {/* </Link> */}
        </Product>
      )
    )
  }

  return (
    <TickerContainer>
      <PageVisibility onChange={isVisible => setPageIsVisible(isVisible)}>
        {pageIsVisible && (
          <Ticker move={move} key={category} speed={16}>
            {({ index }) => (
              <Grid
                onMouseEnter={() => setMove(false)}
                onMouseLeave={() => setMove(true)}
              >
                {productCategory.edges ? (
                  renderProducts(productCategory.edges)
                ) : (
                  <p>No Products found!</p>
                )}
              </Grid>
            )}
          </Ticker>
        )}
      </PageVisibility>
    </TickerContainer>
  )
}

export default ProductGrid
