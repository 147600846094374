import styled from '@emotion/styled'

import { breakpoints } from '~/utils/styles'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`
export const CategoryNav = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  @media (max-width: ${breakpoints.s}px) {
    margin: 0 auto;
    justify-content: center;
  }
`

export const Item = styled.li`
  padding: 0.3rem 0 0.2rem;
  margin: 0 1rem;
  cursor: pointer;
  @media (min-width: ${breakpoints.s}px) {
    &:first-of-type {
      padding-left: 0;
      margin-left: 0;
    }
  }
  ${props => props.isActive && `border-bottom: 2px solid white;`}
`
